import React, { useState, useEffect } from 'react';
import { db, auth } from '../firebaseConfig'; // Import Firebase config
import { collection, getDocs, addDoc } from 'firebase/firestore'; // Firestore functions
import { useUser } from '../UserContext'; // Import the useUser hook
import './Team.css';
import Lottie from 'lottie-react';
import teamlottie from '../assets/teamlottie.json';


const Team = () => {
  const userId = auth.currentUser ? auth.currentUser.uid : null;
  const [team, setTeam] = useState([]);
  const [newMember, setNewMember] = useState({ name: '', mobile: '' });
  const [loading, setLoading] = useState(true);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: teamlottie,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  // Fetch team members from Firestore
  useEffect(() => {
    const fetchTeam = async () => {
      try {
        const teamCollectionRef = collection(db, 'users', userId, 'team');
        const teamSnapshot = await getDocs(teamCollectionRef);
        const teamList = teamSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
        }));
        setTeam(teamList);
      } catch (error) {
        console.error('Error fetching team:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchTeam();
  }, []);

  // Handle adding new team members
  const addTeamMember = async () => {
    if (!newMember.name || !newMember.mobile) {
      alert('Please provide both name and mobile.');
      return;
    }

    try {
      const teamCollectionRef = collection(db, 'users', userId, 'team');
      const newMemberData = {
        name: newMember.name,
        mobile: newMember.mobile,
      };

      // Add the new member document to Firestore
      await addDoc(teamCollectionRef, newMemberData);

      // Update the local state to reflect the new member
      setTeam([...team, newMemberData]);
      setNewMember({ name: '', mobile: '' }); // Reset the form

      alert('Team member added successfully!');
    } catch (error) {
      console.error('Error adding team member:', error);
    }
  };

  return (
    <div className="team-container">
      <div className="lottie-container">
          <Lottie 
            animationData={teamlottie} 
            style={{ height: 200, width: 200 }} 
            loop 
            autoplay 
          />
        </div>

      <h2>Together We Grow...</h2>
  
      {loading ? (
        <p>Loading team members...</p>
      ) : (
        <div>
          {team.length > 0 ? (
            <div>
              <h3>Current Team Members</h3>
              <div>
                {team.map((member, index) => (
                  <div key={index} className="team-member-card">
                    <p><strong>Name:</strong> {member.name}</p>
                    <p><strong>Mobile:</strong> {member.mobile}</p>
                  </div>
                ))}
              </div>
            </div>
          ) : (
            <p>No team members added yet.</p>
          )}
        </div>
      )}
  
      <div className="add-member-form">
        <h3>Add New Team Member</h3>
        <input
          type="text"
          placeholder="Name"
          value={newMember.name}
          onChange={(e) => setNewMember({ ...newMember, name: e.target.value })}
        />
        <input
          type="text"
          placeholder="Mobile"
          value={newMember.mobile}
          onChange={(e) => setNewMember({ ...newMember, mobile: e.target.value })}
        />
        <br />
        <button onClick={addTeamMember}>
          Add Team Member
        </button>
      </div>
    </div>
  );
}  

export default Team;
