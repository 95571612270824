import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth, db } from '../firebaseConfig';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { setDoc, doc } from 'firebase/firestore';
import MultiStepFormLayout from './MultiStepFormLayout'; // Common layout component for all registration steps
import './LawyerRegistrationForm.css'; // Custom CSS for this component
import chamberImage from '../assets/chamber.webp'; // Import the image

function LawyerRegistrationForm({ setUserData }) {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [mobile, setMobile] = useState('');
  const [licenseNumber, setLicenseNumber] = useState('');
  const [errorMessage, setErrorMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false); // Loading state to handle submission
  const navigate = useNavigate();
  const step = 1;
  const totalSteps = 7;

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Check if all required fields are filled
    if (!name || !email || !password || !mobile || !licenseNumber) {
        setErrorMessage('Please fill in all fields.');
        return;
    }

    // Ensure mobile number has +91 prefix
    const formattedMobile = `+91${mobile}`;

    setIsLoading(true); // Start loading

    try {
        // Register user with email and password via Firebase Auth
        const userCredential = await createUserWithEmailAndPassword(auth, email, password);
        const uid = userCredential.user.uid; // Get user ID from Firebase

        // User data to save to Firestore
        const userData = {
            advocateName: name,
            email,
            mobile: formattedMobile,
            licenseNumber,
            role: 'lawyer',
            userId: uid,  // Include userId here
        };

        // Save user data to Firestore under 'users' collection with UID as the document ID
        await setDoc(doc(db, 'users', uid), userData);
        // Set global user data and UID
        setUserData((prev) => ({ ...prev, ...userData }));

        // Navigate to the next step (state selection)
        navigate('/state-selection');
    } catch (error) {
        // Handle errors and show error message
        setErrorMessage(error.message);
    } finally {
        setIsLoading(false); // End loading state
    }
};



  return (
    <MultiStepFormLayout step={step} totalSteps={totalSteps} onNext={handleSubmit} onBack={() => navigate(-1)}>
      <div className="image-container">
        <img src={chamberImage} alt="Chamber" className="chamber-image" />
      </div>
      <form onSubmit={handleSubmit} className="form-content">
        <div className="input-group">
          <input
            type="text"
            placeholder="Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            className="input-field"
          />
        </div>
        <div className="input-group">
          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="input-field"
          />
        </div>
        <div className="input-group">
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="input-field"
          />
        </div>
        <div className="input-group">
          <div className="input-prefix">
            <span>+91</span>
            <input
              type="tel"
              placeholder="Mobile"
              value={mobile}
              onChange={(e) => setMobile(e.target.value.replace(/\D/g, ''))} // Ensure only numbers are entered
              className="input-field"
            />
          </div>
        </div>

        <div className="input-group">
          <input
            type="text"
            placeholder="License Number"
            value={licenseNumber}
            onChange={(e) => setLicenseNumber(e.target.value)}
            className="input-field"
          />
        </div>
        {errorMessage && <p className="error-message">{errorMessage}</p>}
        <button type="submit" className="submit-button" disabled={isLoading}>
          {isLoading ? 'Processing...' : 'Proceed'}
        </button>
      </form>
    </MultiStepFormLayout>
  );
}

export default LawyerRegistrationForm;
