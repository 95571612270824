import React from 'react';
import { useNavigate } from 'react-router-dom';
import MultiStepFormLayout from './MultiStepFormLayout';
import './ReviewSelection.css'; // Importing modern CSS for review layout
import { db, auth } from '../firebaseConfig';
import { doc, setDoc } from 'firebase/firestore';


function ReviewSelection({ userData }) {
  const navigate = useNavigate();
  const userId = auth.currentUser ? auth.currentUser.uid : null;


  const handleRegister = async () => {
    try {
      // Prepare court complexes in the required format
      const courtComplexes = Object.entries(userData.selectedComplexes)
        .flatMap(([state, districtComplexes]) =>
          Object.entries(districtComplexes).flatMap(([district, complexes]) =>
            complexes.map(complex => `${state} - ${district} - ${complex}`)
          )
        );
  
      // Prepare high court states and benches
      const highCourtStates = Object.keys(userData.selectedHCStates);
      const highCourtBenches = highCourtStates.flatMap(
        state => userData.selectedHCStates[state] || []
      );
  
      // Create payload for the API call
      const jsonPayload = {
        advocate_name: userData.advocateName,
        court_complexes: courtComplexes,
        high_court_states: highCourtStates,
        high_court_benches: highCourtBenches,
        bench_state: userData.selectedCATBench || null,
        uuid: userId,
        user_id: userId,
      };
  
      console.log('JSON Payload:', JSON.stringify(jsonPayload, null, 2));
  
      // Make the API call
      const response = await fetch('https://genlawyers.com/run_all_scripts', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(jsonPayload),
      });
  
      // Handle API response
      if (!response.ok) {
        const responseData = await response.json();
        console.error('API Call Failed:', responseData);
      } else {
        navigate(`/dashboard/${userId}`, { state: { fromRegistration: true } });
      }
  
    } catch (error) {
      console.error('Error during registration:', error);
    }
  };
  

  async function saveToFirestore(userId, userData) {
    const courtComplexes = Object.entries(userData.selectedComplexes).flatMap(([state, districtComplexes]) =>
      Object.entries(districtComplexes).flatMap(([district, complexes]) =>
        complexes.map(complex => `${state} - ${district} - ${complex}`)
      )
    );
  
    const highCourtStates = Object.keys(userData.selectedHCStates);
    const highCourtBenches = highCourtStates.flatMap(state => userData.selectedHCStates[state] || []);
  
    const userProfile = {
      advocate_name: userData.advocateName,
      court_complexes: courtComplexes,
      high_court_states: highCourtStates,
      high_court_benches: highCourtBenches,
      bench_state: userData.selectedCATBench || null,
      user_id: userId,
    };
  
    // Save the data to Firestore
    try {
      await setDoc(doc(db, 'users', userId), userProfile, { merge: true });
      console.log('User data saved successfully.');
    } catch (error) {
      console.error('Error saving user data to Firestore:', error);
    }
  }

  return (
    <MultiStepFormLayout 
      step={7} 
      totalSteps={7} 
      onNext={handleRegister} 
      onBack={() => navigate(-1)}
    >
      <div className="review-section">
        <h3>Personal Information</h3>
        <div className="personal-info">
          <p><strong>Name:</strong> {userData.advocateName}</p>
          <p><strong>Email:</strong> {userData.email}</p>
          <p><strong>Mobile:</strong> {userData.mobile}</p>
          <p><strong>License Number:</strong> {userData.licenseNumber}</p>
        </div>
      </div>

      {userData.selectedStates.length > 0 && (
        <div className="review-section">
          <h3>Selected States</h3>
          <ul className="review-list">
            {userData.selectedStates.map((state, index) => (
              <li key={index}>{state}</li>
            ))}
          </ul>
        </div>
      )}

      {Object.keys(userData.selectedComplexes).length > 0 && (
        <div className="review-section">
          <h3>Court Complexes</h3>
          {Object.entries(userData.selectedComplexes).map(([state, districtComplexes], index) => (
            <div key={index} className="complex-state">
              <h4>{state}</h4>
              {Object.entries(districtComplexes).map(([district, complexes], i) => (
                <div key={i} className="complex-district">
                  <strong>{district}</strong>
                  <ul className="review-list">
                    {complexes.map((complex, j) => (
                      <li key={j}>{complex}</li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
          ))}
        </div>
      )}

      {Object.keys(userData.selectedHCStates).length > 0 && (
        <div className="review-section">
          <h3>High Court Selections</h3>
          {Object.entries(userData.selectedHCStates).map(([state, benches], index) => (
            <div key={index} className="high-court-state">
              <strong>{state}</strong>
              <ul className="review-list">
                {benches.length > 0 ? benches.map((bench, i) => (
                  <li key={i}>{bench}</li>
                )) : <li>No benches selected</li>}
              </ul>
            </div>
          ))}
        </div>
      )}

      {userData.selectedCATBench && (
        <div className="review-section">
          <h3>Selected CAT Bench</h3>
          <p>{userData.selectedCATBench}</p>
        </div>
      )}
    </MultiStepFormLayout>
  );
}

export default ReviewSelection;
