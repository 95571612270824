import React, { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { signInWithEmailAndPassword, signInWithCustomToken } from 'firebase/auth';
import { auth, authInstance } from '../firebaseConfig'; // Import Firebase config
import { UserContext } from '../UserContext'; // Import the UserContext
import './Login.css'; // Import your custom CSS
import chamberImage from '../assets/chamber.webp'; // Import the chamber image

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const { setUserData } = useContext(UserContext); // Get setUserData from context
  const [loading, setLoading] = useState(false);

  // Check if there is a stored authToken and userId
  useEffect(() => {
    const authToken = localStorage.getItem('authToken');
    const userId = localStorage.getItem('userId');

    if (authToken && userId) {
      // If there is, log the user in automatically
      auth.onAuthStateChanged((user) => {
        if (user) {
          setUserData((prev) => ({ ...prev, userId }));
          navigate(`/dashboard/${userId}`);
        }
      });
    }
  }, [navigate, setUserData]);

  // Handle authentication state changes
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        user.getIdToken(true).then((token) => {
          const userId = user.uid;
          setUserData((prev) => ({ ...prev, userId }));
          localStorage.setItem('authToken', token);
          localStorage.setItem('userId', userId);
          navigate(`/dashboard/${userId}`);
        });
      }
    });

    return () => {
      unsubscribe();
    };
  }, [navigate, setUserData]);

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      await signInWithEmailAndPassword(auth, email, password);
    } catch (error) {
      setError(error.message);
      setLoading(false); // Reset loading state on error
    }
  };
  

  const handleGoHome = () => {
    navigate('/'); // Navigate to the home page
  };

  return (
    <div className="login-container">
      <img src={chamberImage} alt="Virtual Chamber" className="chamber-image" />
      <h2>Access Your Virtual Chamber</h2>
      <p>Your confirm to be an enrolled Advocate to access and use GenLawyers.</p>
      <form onSubmit={handleLogin}>
        <div>
          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div>
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        {error && <p className="error-message">{error}</p>}
        <button type="submit" className="login-button" disabled={loading}>
          {loading ? <div className="spinner"></div> : 'Login'}
        </button>
      </form>
      <div style={{ marginBottom: '20px' }} /> {/* Space below login button */}
      <button onClick={handleGoHome} className="home-button">Go to Home</button>
    </div>
  );
}

export default Login;