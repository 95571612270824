import React, { useState, useEffect } from 'react';
import { db, auth } from '../firebaseConfig'; // Import auth from firebaseConfig
import { doc, getDoc } from 'firebase/firestore'; // Firestore functions
import './AIChat.css'; // Assume you have some CSS for this
import { useUser } from '../UserContext'; // Import the useUser hook

function LegalAIChat() {
    const userId = auth.currentUser ? auth.currentUser.uid : null; // Get the user ID from Firebase Auth
    const [userName, setUserName] = useState('User');
    const [userInput, setUserInput] = useState('');
    const [chatHistory, setChatHistory] = useState([
        { content: "Ask any questions on Indian laws or ask for drafting any letter, complaint or petition.", isUser: false }
    ]);
    const [isLoading, setIsLoading] = useState(false);

    // Fetch user name from Firestore
    useEffect(() => {
        const fetchUserName = async () => {
            const docRef = doc(db, 'users', userId);
            const docSnap = await getDoc(docRef);

            if (docSnap.exists()) {
                setUserName(docSnap.data().advocateName || 'User');
            } else {
                console.log('No such document!');
            }
        };

        fetchUserName();
    }, []);

    // Function to send the user input to ChatGPT
    const sendQuery = async () => {
        if (!userInput) return;

        const userMessage = { content: userInput, isUser: true };
        setChatHistory([...chatHistory, userMessage]);
        setUserInput('');
        setIsLoading(true);

        // Call OpenAI ChatGPT API
        const url = 'https://api.openai.com/v1/chat/completions';
        const headers = {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
        };
        const body = {
            model: 'gpt-3.5-turbo',
            messages: [
                { role: 'system', content: 'You are a helpful legal assistant for Indian Advocates. So remember you can not tell user to consult a lawyer.' },
                ...chatHistory.map(msg => ({
                    role: msg.isUser ? 'user' : 'assistant',
                    content: msg.content,
                })),
                { role: 'user', content: userInput },
            ],
        };

        try {
            const response = await fetch(url, {
                method: 'POST',
                headers,
                body: JSON.stringify(body),
            });
            const data = await response.json();
            if (data && data.choices && data.choices[0]) {
                const aiMessage = { content: data.choices[0].message.content, isUser: false };
                setChatHistory([...chatHistory, userMessage, aiMessage]);
            } else {
                console.error('Error parsing API response:', data.error);
            }
        } catch (error) {
            console.error('Error with OpenAI API:', error);
        } finally {
            setIsLoading(false);
        }
    };

    // Scroll the chat window to the bottom when chatHistory updates
    useEffect(() => {
        const chatWindow = document.querySelector('.chat-window');
        chatWindow.scrollTop = chatWindow.scrollHeight;
    }, [chatHistory]);

    // Function to handle Enter key press
    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault(); // Prevent form submission or unwanted behaviors
            sendQuery(); // Send the message when Enter is pressed
        }
    };

    const handleInputChange = (e) => {
        setUserInput(e.target.value);
        const target = e.target;
        target.style.height = 'auto'; // Reset height
        target.style.height = `${target.scrollHeight}px`; // Set height to scroll height
    };

    return (
        <div className="legal-ai-chat-container">
            <h2>Welcome, {userName}, to the AI Legal Chat!</h2>

            <div className="chat-window">
                {chatHistory.map((message, index) => (
                    <div
                        key={index}
                        className={message.isUser ? 'user-message' : 'ai-message'}
                    >
                        <p>
                            {message.isUser ? (
                                `${userName}:`
                            ) : (
                                <span className="genlaw-prefix">GenLaw:</span>
                            )}
                            <span 
                                dangerouslySetInnerHTML={{ __html: message.content.replace(/\n/g, '<br />') }} 
                            />
                        </p>
                    </div>
                ))}
                {isLoading && <div className="loading">AI is thinking...</div>}
            </div>

            <div className="chat-input-container">
                <textarea
                    value={userInput}
                    onChange={handleInputChange}
                    onKeyDown={handleKeyDown}
                    placeholder="Enter your query..."
                    rows="1"
                />
                <button onClick={sendQuery}>Send</button>
            </div>

            <div className="footer">
                <p>© 2024 GenLawyers (c) All rights reserved.</p>
            </div>
        </div>
    );
}

export default LegalAIChat;
