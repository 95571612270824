import React, { useState, useEffect } from 'react';
import { Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Modal, TextField, Box, Typography } from '@mui/material';
import './CaseDetail.css';
import { doc, updateDoc, arrayUnion, arrayRemove, collection, getDocs, getDoc, onSnapshot } from 'firebase/firestore'; // Import Firestore functions
import { db, storage, auth } from '../firebaseConfig'; // Import Firebase config and Storage
import { generateInvoicePDF } from './generateInvoicePdf';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage'; // Firebase storage functions

function CaseDetail({ caseData }) {
    const [invoiceData, setInvoiceData] = useState({ amount: '', description: '', date: new Date().toISOString().substr(0, 10), });
    const [paymentData, setPaymentData] = useState({ amount: '', description: '', date: new Date().toISOString().substr(0, 10), });
    const [noteData, setNoteData] = useState({ content: '', date: new Date().toISOString().substr(0, 10), });
    const [todoData, setTodoData] = useState({ description: '', isCompleted: false });
    const [isFeeModalOpen, setIsFeeModalOpen] = useState(false);
    const [isInvoiceModalOpen, setIsInvoiceModalOpen] = useState(false);
    const [isNoteModalOpen, setIsNoteModalOpen] = useState(false);
    const [isTodoModalOpen, setIsTodoModalOpen] = useState(false);
    const [isUploading, setIsUploading] = useState(false);
    const [file, setFile] = useState(null);
    const [uploadedFiles, setUploadedFiles] = useState(caseData.uploadedFiles || []);
    const { petitionerAdvocate, respondentAdvocate, history, orders } = caseData;
    const [teamMembers, setTeamMembers] = useState([]); // Store team members
    const [caseDataState, setCaseData] = useState(caseData); // Define setCaseData state updater function
    const [caseDetail, setCaseDetail] = useState(caseData); // Initial case data from props
    const userId = auth.currentUser ? auth.currentUser.uid : null;
    


    // State to toggle visibility of case status, history, and orders sections
    const [isCaseStatusExpanded, setIsCaseStatusExpanded] = useState(false);
    const [isHistoryExpanded, setIsHistoryExpanded] = useState(false);
    const [isOrdersExpanded, setIsOrdersExpanded] = useState(false);
  
    const handleToggleCaseStatus = () => setIsCaseStatusExpanded(!isCaseStatusExpanded);
    const handleToggleHistory = () => setIsHistoryExpanded(!isHistoryExpanded);
    const handleToggleOrders = () => setIsOrdersExpanded(!isOrdersExpanded);
  
    useEffect(() => {
      const fetchTeamMembers = async () => {
          const teamCollectionRef = collection(db, 'users', userId, 'team');
          const teamSnapshot = await getDocs(teamCollectionRef);
          const members = teamSnapshot.docs.map(doc => doc.data());
          setTeamMembers(members);
      };
  
      fetchTeamMembers();
  }, [userId]);

  
  
    // File upload handling
    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    };

    const handleFileUpload = async () => {
        if (!file) {
            alert("Please select a file to upload.");
            return;
        }

        setIsUploading(true);

        try {
            const storageRef = ref(storage, `caseFiles/${caseData.id}/${file.name}`);
            await uploadBytes(storageRef, file);
            const fileURL = await getDownloadURL(storageRef);

            const caseDocRef = doc(db, 'users', userId, 'CaseDetails', caseData.id);
            const newFile = {
                name: file.name,
                url: fileURL,
                uploadedAt: new Date(),
            };

            // Update Firestore
            await updateDoc(caseDocRef, {
                uploadedFiles: arrayUnion(newFile),
            });

            // Update the state to reflect the new uploaded file
            setUploadedFiles((prevFiles) => [...prevFiles, newFile]);

            alert("File uploaded successfully!");
            setFile(null); // Reset file input after upload
        } catch (error) {
            console.error("Error uploading file:", error);
            alert("Error uploading file.");
        } finally {
            setIsUploading(false);
        }
    };

    const handleFeeReceived = async () => {
      if (!paymentData.amount || !paymentData.description || !paymentData.date) {
        alert("Please fill all the fields for Fee Received.");
        return;
      }
    
      const payment = {
        amount: paymentData.amount,
        description: paymentData.description,
        date: paymentData.date,
      };
    
      try {
        const caseDocRef = doc(db, 'users', userId, 'CaseDetails', caseData.id);
        console.log("Attempting to add fee to Firestore:", payment); // Debugging: Log payment details
    
        await updateDoc(caseDocRef, {
          payments: arrayUnion(payment),
        });
    
        console.log("Fee added successfully to Firestore"); // Debugging: Log after Firestore update
    
        // Close the modal after successful update
        setIsFeeModalOpen(false);
    
        console.log("Local state updated with new payment", payment); // Debugging: Log updated state
        
        alert("Fee received successfully.");
      } catch (error) {
        console.error("Error adding fee received:", error);
        alert("Error adding fee received.");
      }
    };
    
    
    const handleGenerateInvoice = async () => {
      if (!invoiceData.amount || !invoiceData.description || !invoiceData.date) {
          alert("Please fill all the fields for Invoice Generation.");
          return;
      }
  
      // Ensure petitioner and respondent advocates are defined as arrays
      const petitionerAdvocate = Array.isArray(caseDetail.petitionerAdvocate) 
          ? caseDetail.petitionerAdvocate.join(", ") 
          : caseDetail.petitionerAdvocate || "Unknown Petitioner";
  
      const respondentAdvocate = Array.isArray(caseDetail.respondentAdvocate) 
          ? caseDetail.respondentAdvocate.join(", ") 
          : caseDetail.respondentAdvocate || "Unknown Respondent";
  
      const invoice = {
          amount: invoiceData.amount,
          description: invoiceData.description,
          date: invoiceData.date,
      };
  
      try {
          // Save invoice to Firestore
          const caseDocRef = doc(db, 'users', userId, 'CaseDetails', caseData.id); // Use caseData.id as caseId
          await updateDoc(caseDocRef, {
              pendingInvoices: arrayUnion(invoice), // Add the new invoice to the existing "pendingInvoices" array
          });
  
          console.log("Invoice saved to Firestore:", invoice);
          alert("Invoice saved to Firestore successfully.");
  
          // Generate and open the invoice PDF
          await generateInvoicePDF(
              invoice,
              userId,
              petitionerAdvocate, // Pass formatted petitioner advocate
              respondentAdvocate   // Pass formatted respondent advocate
          );
  
          // Optionally, reset the invoice form after submission
          setInvoiceData({ amount: '', description: '', date: '' });
      } catch (error) {
          console.error("Error generating invoice:", error);
          alert("Error generating invoice.");
      }
  };
  
      
    
      const handleAddNote = async () => {
        if (!noteData.content || !noteData.date) {
          alert("Please fill all the fields for Note.");
          return;
        }
      
        const note = {
          content: noteData.content,
          date: new Date(noteData.date),
        };
      
        try {
          const caseDocRef = doc(db, 'users', userId, 'CaseDetails', caseData.id);
          await updateDoc(caseDocRef, {
            notes: arrayUnion(note),
          });
      
          console.log("Note added:", note);
          alert("Note added successfully.");
          
          // Reset the note data
          setNoteData({ content: '', date: '' });
      
          // Close the modal
          setIsNoteModalOpen(false);
        } catch (error) {
          console.error("Error adding note:", error);
          alert("Error adding note.");
        }
      };
      
    const handleAddTodo = async () => {
    if (!todoData.description || !todoData.date || !todoData.assignedTo) {
        alert("Please fill all the fields for To-Do.");
        return;
    }

    const todo = {
        description: todoData.description,
        date: new Date(todoData.date), // Save the selected date
        assignedTo: todoData.assignedTo, // Save the assigned team member
        isCompleted: todoData.isCompleted,
    };

    try {
        const caseDocRef = doc(db, 'users', userId, 'CaseDetails', caseData.id);
        await updateDoc(caseDocRef, {
            toDos: arrayUnion(todo),
        });
        console.log("To-Do added:", todo);
        alert("To-Do added successfully.");
        setTodoData({ description: '', date: '', assignedTo: '', isCompleted: false }); // Reset after saving
        setIsTodoModalOpen(false); // Close the modal
    } catch (error) {
        console.error("Error adding To-Do:", error);
        alert("Error adding To-Do.");
    }
};


    const handleCompleteTodo = async (todo) => {
        try {
            const caseDocRef = doc(db, 'users', userId, 'CaseDetails', caseData.id);
            await updateDoc(caseDocRef, {
                toDos: arrayRemove(todo) // Remove the completed to-do item from the array
            });
            console.log('To-Do marked as completed:', todo);
            alert('To-Do completed and removed successfully.');
        } catch (error) {
            console.error('Error marking To-Do as complete:', error);
            alert('Error completing To-Do.');
        }
    };
    
    const handleInvoicePaid = async (invoice) => {
      const confirmPayment = window.confirm("Are you sure you want to mark this invoice as paid?");
      if (confirmPayment) {
          try {
              const caseDocRef = doc(db, 'users', userId, 'CaseDetails', caseData.id);
              
              // Remove invoice from pendingInvoices and add to payments
              await updateDoc(caseDocRef, {
                  pendingInvoices: arrayRemove(invoice), // Remove the pending invoice
                  payments: arrayUnion(invoice) // Add the invoice to payments
              });
              
              alert("Invoice marked as paid successfully.");
          } catch (error) {
              console.error("Error marking invoice as paid:", error);
              alert("Error marking invoice as paid.");
          }
      }
    };
    
    const parseCaseData = (caseData) => {
      switch (caseData.caseType) {
        case 'HighCourt':
          const petitionerAdvocate = caseData.petitioner_advocate || "Unknown Petitioner";
          const respondentAdvocate = caseData.respondent_advocate || "Unknown Respondent";
          const nextHearingDate = caseData.next_hearing_date || "No Next Hearing Date";
          const courtDetails = caseData.case_details || {};
          const caseStatus = caseData.case_status || {};
          return {
            ...caseData,
            payments: caseData.payments || [], // Include payments
            pendingInvoices: caseData.pendingInvoices || [], // Include pendingInvoices
            petitionerAdvocate,
            respondentAdvocate,
            nextHearingDate,
            courtNumber: courtDetails["Registration Number"] || "Unknown Court",
            case_status: caseStatus,
          };
        case 'DistrictCourt':
          const petitioners = caseData.Petitioners || ["Unknown Petitioner"];
          const respondents = caseData.Respondents || ["Unknown Respondent"];
          const nextHearingDateDC = caseData.NextHearingDate || "No Next Hearing Date";
          return {
            ...caseData,
            payments: caseData.payments || [], // Include payments
            pendingInvoices: caseData.pendingInvoices || [], // Include pendingInvoices
            petitionerAdvocate: petitioners.join(", "),
            respondentAdvocate: respondents.join(", "),
            nextHearingDate: nextHearingDateDC,
            courtNumber: caseData["Court Number and Judge"] || "Unknown Court",
            caseStage: caseData["Case Stage"] || "N/A",
          };
        default:
          return caseData;
      }
    };
    
  
  useEffect(() => {
    const caseDocRef = doc(db, 'users', userId, 'CaseDetails', caseData.id);
    const unsubscribe = onSnapshot(caseDocRef, (doc) => {
        if (doc.exists()) {
            const snapshotData = parseCaseData(doc.data());
            console.log("Parsed case data:", snapshotData); // Debugging output
            setCaseDetail(snapshotData);
        } else {
            console.log("Document does not exist");
        }
    });

    return () => unsubscribe();
}, [userId, caseData.id]);


    
    return (
      
      <div>
      {/* Display Court Type */}
      <div className="card">

      <h3>{caseDetail.caseType === 'HighCourt' ? 'High Court' : 'District Court'}</h3>
      
      {/* Display Case Number based on Court Type */}
      <p>
          <strong>Case#:</strong> 
          {caseDetail.caseType === 'HighCourt' ? 
              caseDetail.courtNumber || "N/A" : // For High Court, use courtNumber
              caseDetail.CNRNumber || caseDetail.filingNumber || "N/A" // For District Court, use CNRNumber or Filing Number
          }
      </p>
      
      {caseDetail.caseType === 'DistrictCourt' && (
        <p><strong>Court:</strong> {caseDetail.courtNumber || "Unknown Court"}</p>
    )}
      <p><strong>Next Hearing Date:</strong> {caseDetail.nextHearingDate || "No Next Hearing Date"}</p>
  
     {/* Display Business from case_hearing_history for High Court */}
      {caseDetail.caseType === 'HighCourt' && caseDetail.case_hearing_history?.length > 1 && (
          <p>
              <strong>Judge:</strong> {caseDetail.case_hearing_history[1]?.business || "N/A"}
          </p>
      )}
  
      {/* Display Petitioners and Respondents */}
      <p><strong>Petitioners:</strong> {caseDetail.petitionerAdvocate || "N/A"}</p>
      <p><strong>Respondents:</strong> {caseDetail.respondentAdvocate || "N/A"}</p>
      </div>

      <div className="card">

        {/* ChatGPT Suggestions */}
        <h3>Tips for Next Hearing:-</h3>
        <p>{caseData.chatGptSuggestions}</p>
        </div>

        <div className="card">

        <div className="billing-section">
    <h3>Billing</h3>

    {/* Fee Received Section */}
    <h4>Fee Received</h4>
    {caseData.payments && caseData.payments.length > 0 ? (
  <>
    <table className="billing-table">
      <thead>
        <tr>
          <th>Amount</th>
          <th>Description</th>
          <th>Date</th>
        </tr>
      </thead>
      <tbody>
        {caseData.payments.map((payment, index) => (
          <tr key={index}>
            <td>{payment.amount}</td>
            <td>{payment.description}</td>
            <td>{payment.date}</td>
          </tr>
        ))}
      </tbody>
    </table>
    <p><strong>Total Fee Received:</strong> {caseData.payments.reduce((total, payment) => total + Number(payment.amount), 0)}</p>
  </>
) : (
  <p>No fees received yet.</p>
)}

<button onClick={() => setIsFeeModalOpen(true)}>Fee Received</button>

</div>


{/* Pending Invoices Section */}
<h4>Pending Invoices</h4>
{caseData.pendingInvoices && caseData.pendingInvoices.length > 0 ? (
    <table className="billing-table">
        <thead>
            <tr>
                <th>Amount</th>
                <th>Description</th>
                <th>Date</th>
                <th>Action</th> {/* New column for Paid button */}
            </tr>
        </thead>
        <tbody>
            {caseData.pendingInvoices.map((invoice, index) => (
                <tr key={index}>
                    <td>{invoice.amount}</td>
                    <td>{invoice.description}</td>
                    <td>{invoice.date}</td>
                    <td>
                        <button onClick={() => handleInvoicePaid(invoice)}>Paid</button>
                    </td>
                </tr>
            ))}
        </tbody>
    </table>
) : (
    <p>No pending invoices.</p>
)}
    {/* Buttons to Open Modals */}
    <button onClick={() => setIsInvoiceModalOpen(true)}>Generate Invoice</button>
</div>
{/* Fee Received Modal */}
{isFeeModalOpen && (
  <div className="modal">
    <div className="modal-content">
      <h4>Enter Fee Details</h4>
      <input
        type="text"
        placeholder="Amount"
        value={paymentData.amount}
        onChange={(e) => setPaymentData({ ...paymentData, amount: e.target.value })}
      />
      <input
        type="text"
        placeholder="Description"
        value={paymentData.description}
        onChange={(e) => setPaymentData({ ...paymentData, description: e.target.value })}
      />
      <input
        type="date"
        defaultValue={paymentData.date} // set default value to current date
        onChange={(e) => setPaymentData({ ...paymentData, date: e.target.value })}
      />
      <button onClick={handleFeeReceived}>Save</button>
      <button onClick={() => setIsFeeModalOpen(false)}>Cancel</button>
    </div>
  </div>
)}

            {/* Generate Invoice Modal */}
{isInvoiceModalOpen && (
    <div className="modal">
        <div className="modal-content">
            <h4>Enter Invoice Details</h4>
            <input
                type="text"
                placeholder="Amount"
                value={invoiceData.amount}
                onChange={(e) => setInvoiceData({ ...invoiceData, amount: e.target.value })}
            />
            <input
                type="text"
                placeholder="Description"
                value={invoiceData.description}
                onChange={(e) => setInvoiceData({ ...invoiceData, description: e.target.value })}
            />
            <input
                type="date"
                value={invoiceData.date}
                onChange={(e) => setInvoiceData({ ...invoiceData, date: e.target.value })}
            />
            <button onClick={handleGenerateInvoice}>
                Generate Invoice PDF
            </button>
            <button onClick={() => setIsInvoiceModalOpen(false)}>Cancel</button>
        </div>
    </div>
)}

<div className="card">


{/* Notes Section */}
<div className="notes-section" style={{ marginTop: '40px', marginBottom: '20px' }}>
  <h3>Notes</h3>
  {caseData.notes && caseData.notes.length > 0 ? (
    <ul style={{ listStyleType: 'none', padding: 0 }}>
      {caseData.notes.map((note, index) => {
        const date = note.date && note.date.seconds
          ? new Date(note.date.seconds * 1000).toLocaleDateString() // Handle Timestamp
          : new Date(note.date).toLocaleDateString(); // Handle regular Date string or object
        return (
          <li key={index} style={{ marginBottom: '10px' }}>
            <span><strong>{date}:</strong> {note.content}</span>
          </li>
        );
      })}
    </ul>
  ) : (
    <p>No notes available.</p>
  )}
  {/* Add Note Button */}
  <button style={{ marginTop: '10px' }} onClick={() => setIsNoteModalOpen(true)}>Add Note</button>

  {/* Note Modal */}
  {isNoteModalOpen && (
    <div className="modal">
      <div className="modal-content">
        <h4>Enter Note Details</h4>
        <textarea
          placeholder="Note Content"
          value={noteData.content}
          onChange={(e) => setNoteData({ ...noteData, content: e.target.value })}
          style={{ width: '100%', height: '100px' }}
        />
        <input
          type="date"
          value={noteData.date}
          onChange={(e) => setNoteData({ ...noteData, date: e.target.value })}
          style={{ marginTop: '10px' }}
        />
        <button onClick={handleAddNote} style={{ marginTop: '10px' }}>Save Note</button>
        <button onClick={() => setIsNoteModalOpen(false)} style={{ marginTop: '10px' }}>Cancel</button>
      </div>
    </div>
  )}
</div>
{/* To-Do Section */}
<div className="todos-section" style={{ marginBottom: '20px' }}>
  <h3>To-Dos</h3>
  {caseData.toDos && caseData.toDos.length > 0 ? (
    <ul style={{ listStyleType: 'none', padding: 0 }}>
      {caseData.toDos.map((todo, index) => {
        const date = todo.date && todo.date.seconds
          ? new Date(todo.date.seconds * 1000).toLocaleDateString() // Handle Timestamp
          : new Date(todo.date).toLocaleDateString(); // Handle regular Date string or object

        return (
          <li key={index} style={{ marginBottom: '10px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <span><strong>{date}:</strong> {todo.description}</span>
            <button
              onClick={() => handleCompleteTodo(todo)}
              style={{
                padding: '8px 12px',
                marginLeft: '20px',
                backgroundColor: '#4CAF50',
                color: 'white',
                border: 'none',
                borderRadius: '5px',
                cursor: 'pointer',
              }}
            >
              Complete
            </button>
          </li>
        );
      })}
    </ul>
  ) : (
    <p>No to-dos available.</p>
  )}
  {/* Add To-Do Button */}
  <button style={{ marginTop: '10px' }} onClick={() => setIsTodoModalOpen(true)}>Add To-Do</button>
</div>

{/* To-Do Modal */}
{isTodoModalOpen && (
    <div className="modal">
        <div className="modal-content">
            <h4>Enter To-Do Details</h4>

            {/* To-Do Description */}
            <input
                type="text"
                placeholder="Description"
                value={todoData.description}
                onChange={(e) => setTodoData({ ...todoData, description: e.target.value })}
            />
            
            {/* Date Picker */}
            <label>
                <strong>Due Date:</strong>
                <input
                    type="date"
                    value={todoData.date}
                    onChange={(e) => setTodoData({ ...todoData, date: e.target.value })}
                    style={{ marginTop: '10px', width: '100%' }}
                />
            </label>

            {/* Team Member Dropdown */}
            <label>
                <strong>Assign to:</strong>
                <select
                    value={todoData.assignedTo}
                    onChange={(e) => setTodoData({ ...todoData, assignedTo: e.target.value })}
                    style={{ marginTop: '10px', width: '100%' }}
                >
                    <option value="">Select Team Member</option>
                    {teamMembers.map((member, index) => (
                        <option key={index} value={member.name}>
                            {member.name} - {member.mobile}
                        </option>
                    ))}
                </select>
            </label>

            {/* Completed Checkbox */}
            <label>
                <input
                    type="checkbox"
                    checked={todoData.isCompleted}
                    onChange={(e) => setTodoData({ ...todoData, isCompleted: e.target.checked })}
                    style={{ marginTop: '10px' }}
                />
                Completed
            </label>

            {/* Save and Cancel Buttons */}
            <div style={{ marginTop: '20px' }}>
                <button onClick={handleAddTodo} style={{ marginRight: '10px' }}>Save</button>
                <button onClick={() => setIsTodoModalOpen(false)}>Cancel</button>
            </div>
        </div>
    </div>
)}

</div>

<div className="card">

{/* Upload File Section */}
<div className="file-upload-section" style={{ marginTop: '40px', marginBottom: '20px' }}>
                <h3>Upload Documents</h3>
                <input type="file" onChange={handleFileChange} />
                <button onClick={handleFileUpload} disabled={isUploading}>
                    {isUploading ? 'Uploading...' : 'Upload'}
                </button>
            </div>

            {/* Display Uploaded Files */}
            <div className="uploaded-files-section" style={{ marginTop: '40px', marginBottom: '20px' }}>
                <h3>Uploaded Files</h3>
                {uploadedFiles && uploadedFiles.length > 0 ? (
                    <ul style={{ listStyleType: 'none', padding: 0 }}>
                        {uploadedFiles.map((file, index) => (
                            <li key={index} style={{ marginBottom: '10px' }}>
                                <a href={file.url} target="_blank" rel="noopener noreferrer">
                                    {file.name}
                                </a>
                            </li>
                        ))}
                    </ul>
                ) : (
                    <p>No files uploaded yet.</p>
                )}
            </div>
        
            </div>
            <div className="card">

      {/* Case Status Section */}
<div className="expandable-section" onClick={handleToggleCaseStatus} style={{ cursor: 'pointer' }}>
  <h3>Case Status {isCaseStatusExpanded ? '▲' : '▼'}</h3>
  
  {/* Check if Case Status is Expanded */}
  {isCaseStatusExpanded && (
    <div className="expandable-content">
      {/* HighCourt Case Details */}
      {caseDetail?.caseType === "HighCourt" && caseDetail?.case_status ? (
        <>
          <p><strong>Stage:</strong> {caseDetail.case_status["Stage of Case"] || "N/A"}</p>
          <p><strong>Bench Type:</strong> {caseDetail.case_status["Bench Type"] || "N/A"}</p>
          <p><strong>Coram:</strong> {caseDetail.case_status["Coram"] || "N/A"}</p>
          <p><strong>Judicial Branch:</strong> {caseDetail.case_status["Judicial Branch"] || "N/A"}</p>
          <p><strong>State:</strong> {caseDetail.case_status["State"] || "N/A"}</p>
        </>
      ) : null}

      {/* DistrictCourt Case Details */}
      {caseDetail?.caseType === "DistrictCourt" && (
        <>
          <p><strong>Case Stage:</strong> {caseDetail["Case Stage"] || "N/A"}</p>
          <p><strong>Case Type:</strong> {caseDetail["Case Type"] || "N/A"}</p>
        </>
      )}
    </div>
  )}
</div>

{/* Expandable History in Table Format */}
<div className="expandable-section" onClick={handleToggleHistory} style={{ cursor: 'pointer' }}>
  <h3>History {isHistoryExpanded ? '▲' : '▼'}</h3>
  {isHistoryExpanded && (
    <div className="expandable-content">
      <table style={{ width: '100%', borderCollapse: 'collapse' }}>
        <thead>
          <tr>
            <th style={{ border: '1px solid #ddd', padding: '8px' }}>Business</th>
            <th style={{ border: '1px solid #ddd', padding: '8px' }}>Hearing Date</th>
            <th style={{ border: '1px solid #ddd', padding: '8px' }}>
              {caseDetail.caseType === "HighCourt" ? "Next Hearing Date" : "Next Hearing"}
            </th>
            <th style={{ border: '1px solid #ddd', padding: '8px' }}>Purpose</th>
          </tr>
        </thead>
        <tbody>
          {caseDetail.caseType === "HighCourt" && caseDetail.case_hearing_history && caseDetail.case_hearing_history.length > 0 ? (
            caseDetail.case_hearing_history.map((item, index) => (
              <tr key={index}>
                <td>{item.business || 'N/A'}</td>
                <td>{item.hearing_date || 'N/A'}</td>
                <td>{item.next_hearing_date || 'N/A'}</td>
                <td>{item.purpose || 'N/A'}</td>
              </tr>
            ))
          ) : caseDetail.caseType === "DistrictCourt" && caseDetail.CaseHistory && caseDetail.CaseHistory.length > 0 ? (
            caseDetail.CaseHistory.map((item, index) => (
              <tr key={index}>
                <td>{item.BusinessOnDate || 'N/A'}</td>
                <td>{item.HearingDate || 'N/A'}</td>
                <td>{item.NextHearingDate || 'N/A'}</td>
                <td>{item.PurposeOfHearing || 'N/A'}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={4}>No case history available</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  )}
</div>

{/* Expandable Orders in Table Format */}
<div className="expandable-section" onClick={handleToggleOrders} style={{ cursor: 'pointer' }}>
  <h3>
    Orders {isOrdersExpanded ? '▲' : '▼'}
  </h3>
  {isOrdersExpanded && caseData.orders && caseData.orders.length > 0 ? (
    <div className="expandable-content">
      <table style={{ width: '100%', borderCollapse: 'collapse' }}>
        <thead>
          <tr>
            <th style={{ border: '1px solid #ddd', padding: '8px' }}>Order Date</th>
            <th style={{ border: '1px solid #ddd', padding: '8px' }}>Judge</th>
            <th style={{ border: '1px solid #ddd', padding: '8px' }}>Order On</th>
            <th style={{ border: '1px solid #ddd', padding: '8px' }}>Order Link</th>
          </tr>
        </thead>
        <tbody>
          {caseData.orders.map((order, index) => (
            <tr key={index}>
              <td style={{ border: '1px solid #ddd', padding: '8px' }}>{order.order_date || 'N/A'}</td>
              <td style={{ border: '1px solid #ddd', padding: '8px' }}>{order.judge || 'N/A'}</td>
              <td style={{ border: '1px solid #ddd', padding: '8px' }}>{order.order_on || 'N/A'}</td>
              <td style={{ border: '1px solid #ddd', padding: '8px' }}>
                <a href={order.pdf_url} target="_blank" rel="noopener noreferrer">View PDF</a>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  ) : null}
</div>

</div>

      </div>
    );
  }
  

export default CaseDetail;
