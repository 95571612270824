import React, { useState, useEffect } from 'react';
import { db, auth } from '../firebaseConfig'; // Import Firebase config
import { collection, doc, getDoc, getDocs, updateDoc, arrayRemove, addDoc, arrayUnion } from 'firebase/firestore';
import './TodoPage.css'; // Import custom CSS for modern design
import { useCallback } from 'react'; // Import useCallback


const UpcomingTodos = () => {
    const [todos, setTodos] = useState([]);
    const [loading, setLoading] = useState(true);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [newTodo, setNewTodo] = useState({ description: '', assignedTo: '', date: '' });
    const [pendingInvoices, setPendingInvoices] = useState([]); // State for pending invoices
    const [teamMembers, setTeamMembers] = useState([]); // To store team members for assigning to-do
    const userId = auth.currentUser ? auth.currentUser.uid : null;

    // Fetch team members (including self)
    const fetchTeamMembers = useCallback(async () => {
        if (!userId) return;
        try {
            const teamCollectionRef = collection(db, 'users', userId, 'team');
            const teamSnapshot = await getDocs(teamCollectionRef);
            const members = teamSnapshot.docs.map(doc => doc.data());
            setTeamMembers([{ name: 'Self', id: userId }, ...members]);
        } catch (error) {
            console.error('Error fetching team members:', error);
        }
    }, [userId]);

    // Fetch To-Dos and Pending Invoices
    const fetchTodosAndInvoices = useCallback(async () => {
        if (!userId) {
            console.error("User is not logged in.");
            setLoading(false);
            return;
        }
    
        try {
            const caseCollectionRef = collection(db, 'users', userId, 'CaseDetails');
            const teamTodoCollectionRef = collection(db, 'users', userId, 'teamTodos');
    
            const caseSnapshot = await getDocs(caseCollectionRef);
            const teamTodoSnapshot = await getDocs(teamTodoCollectionRef);
    
            let allTodos = [];
            let allInvoices = [];
    
            // Fetch To-Dos from CaseDetails
            caseSnapshot.forEach((doc) => {
                const rawCaseData = doc.data();
                const parsedCaseData = parseCaseData(rawCaseData);
    
                if (rawCaseData.toDos && rawCaseData.toDos.length > 0) {
                    const caseTodos = rawCaseData.toDos.map(todo => ({
                        ...todo,
                        caseId: doc.id,
                        caseTitle: `${parsedCaseData.petitionerAdvocate} vs ${parsedCaseData.respondentAdvocate}`,
                        date: todo.date.toDate()
                    }));
                    allTodos = allTodos.concat(caseTodos);
                }
    
                if (rawCaseData.pendingInvoices && rawCaseData.pendingInvoices.length > 0) {
                    const caseInvoices = rawCaseData.pendingInvoices.map(invoice => ({
                        ...invoice,
                        caseId: doc.id,
                        caseTitle: `${parsedCaseData.petitionerAdvocate} vs ${parsedCaseData.respondentAdvocate}`,
                        dueDate: invoice.dueDate?.toDate(),
                        amount: invoice.amount || "N/A"
                    }));
                    allInvoices = allInvoices.concat(caseInvoices);
                }
            });
    
                        // Fetch To-Dos from teamTodos
            teamTodoSnapshot.forEach((doc) => {
                const teamTodoData = doc.data();
                const todo = {
                    id: doc.id,  // Capture the Firestore document ID
                    ...teamTodoData,
                    date: teamTodoData.date.toDate(),
                    caseTitle: 'General To-Do',  // Since these to-dos are not related to a specific case
                };
                allTodos.push(todo);
            });

    
            // Sort todos and invoices by date
            const sortedTodos = allTodos.sort((a, b) => a.date - b.date);
            const sortedInvoices = allInvoices.sort((a, b) => a.dueDate - b.dueDate);
    
            setTodos(sortedTodos);
            setPendingInvoices(sortedInvoices);
        } catch (error) {
            console.error("Error fetching to-dos and invoices:", error);
        } finally {
            setLoading(false);
        }
    }, [userId]);
    
    useEffect(() => {
        fetchTodosAndInvoices();
        fetchTeamMembers();
    }, [fetchTodosAndInvoices, fetchTeamMembers]);
    
    const handleInvoicePaid = async (invoice) => {
        const confirmPayment = window.confirm("Are you sure you want to mark this invoice as paid?");
        if (confirmPayment) {
            try {
                const caseDocRef = doc(db, 'users', userId, 'CaseDetails', invoice.caseId);
    
                // Fetch the case document directly to log the actual pendingInvoices array
                const caseDocSnapshot = await getDoc(caseDocRef);
                const caseData = caseDocSnapshot.data();
                console.log("Current pendingInvoices in Firestore:", caseData.pendingInvoices);
    
                // Find the exact invoice in Firestore (ensure date is correctly formatted)
                const invoiceInFirestore = caseData.pendingInvoices.find(
                    (inv) => inv.amount === invoice.amount && inv.description === invoice.description && inv.date === invoice.date
                );
    
                if (!invoiceInFirestore) {
                    alert('Error: Invoice not found in Firestore.');
                    return;
                }
    
                console.log("Invoice to Remove:", invoiceInFirestore);
    
                // Remove the invoice from pendingInvoices and add it to payments
                await updateDoc(caseDocRef, {
                    pendingInvoices: arrayRemove(invoiceInFirestore), // Remove the pending invoice
                    payments: arrayUnion(invoiceInFirestore) // Add the invoice to payments
                });
    
                alert("Invoice marked as paid successfully.");
    
                // Refetch To-Dos and Pending Invoices to reflect changes
                fetchTodosAndInvoices();
            } catch (error) {
                console.error("Error marking invoice as paid:", error);
                alert("Error marking invoice as paid.");
            }
        }
    };
    

    
    const parseCaseData = (caseData) => {
        switch (caseData.caseType) {
          case 'HighCourt':
            // Removing numbers like "1)" and joining petitioner and respondent names
            const petitionerAdvocateHighCourt = (caseData.petitioner_advocate || '').replace(/^1\)\s*/, '') || "Unknown Petitioner";
            const respondentAdvocateHighCourt = (caseData.respondent_advocate || '').replace(/^1\)\s*/, '') || "Unknown Respondent";
            return {
              ...caseData,
              petitionerAdvocate: petitionerAdvocateHighCourt,
              respondentAdvocate: respondentAdvocateHighCourt,
            };
            
          case 'DistrictCourt':
            // For district court, assuming arrays of petitioners/respondents
            const petitioners = (caseData.Petitioners || []).map(pet => pet.replace(/^1\)\s*/, '')).join(", ");
            const respondents = (caseData.Respondents || []).map(res => res.replace(/^1\)\s*/, '')).join(", ");
            return {
              ...caseData,
              petitionerAdvocate: petitioners || "Unknown Petitioner",
              respondentAdvocate: respondents || "Unknown Respondent",
            };
            
          default:
            return caseData;
        }
      };
      
      
    

    // Add a new To-Do (not related to any case)
    const handleAddTodo = async () => {
        if (!newTodo.description || !newTodo.assignedTo || !newTodo.date) {
            alert('Please fill in all fields.');
            return;
        }

        try {
            const teamTodoCollectionRef = collection(db, 'users', userId, 'teamTodos');
            const newTodoData = {
                description: newTodo.description,
                assignedTo: newTodo.assignedTo,
                date: new Date(newTodo.date),
                isCompleted: false
            };

            // Save the new to-do to Firestore
            await addDoc(teamTodoCollectionRef, newTodoData);

            // Reset the form and close the modal
            setNewTodo({ description: '', assignedTo: '', date: '' });
            setIsModalOpen(false);

            // Refetch todos to include the new one
            fetchTodosAndInvoices();

            alert('To-Do added successfully!');
        } catch (error) {
            console.error('Error adding new to-do:', error);
        }
    };
    
    const handleCompleteTodo = async (todo) => {
        const confirmDelete = window.confirm("Are you sure you want to mark this to-do as done?");
        if (confirmDelete) {
            try {
                if (todo.id) {
                    const teamTodoDocRef = doc(db, 'users', userId, 'teamTodos', todo.id); // Use todo.id
                    await updateDoc(teamTodoDocRef, {
                        isCompleted: true // Mark the To-Do as completed
                    });
                    
                    setTodos(todos.filter(t => t.id !== todo.id)); // Remove the completed todo from the state
                    alert('Team To-Do marked as completed successfully.');
                } else {
                    alert('Error: To-Do does not have a valid ID.');
                }
            } catch (error) {
                console.error('Error marking To-Do as complete:', error);
                alert('Error completing To-Do.');
            }
        }
    };
    
    

    return (
        <div className="todo-page">
            <h2 className="page-title">Upcoming To-Dos</h2>
            <button className="add-todo-btn" onClick={() => setIsModalOpen(true)}>Add To-Do</button>
    
            {/* Display To-Dos */}
            <div className="todo-list">
                <h2>Upcoming To-Dos</h2>
                {todos.length === 0 ? (
                    <p>No upcoming to-dos.</p>
                ) : (
                    todos.map((todo, index) => (
                        <div key={index} className="todo-card">
                            <strong>{todo.description}</strong><br />
                            <span>Due: {todo.date ? todo.date.toLocaleDateString() : 'No due date available'}</span><br />
                            <span>Assigned to: {todo.assignedTo}</span><br />
                            <span>Case: {todo.caseTitle}</span><br />
                            <button className="complete-btn" onClick={() => handleCompleteTodo(todo)}>Done</button>
                        </div>
                    ))
                )}
            </div>
    
            <div className="invoice-list">
    <h2>Pending Invoices</h2>
    {pendingInvoices.length === 0 ? (
        <p>No pending invoices.</p>
    ) : (
        pendingInvoices.map((invoice, index) => {
            // Ensure the date is a valid Date object before calling toLocaleDateString
            const invoiceDate = invoice.date instanceof Date ? invoice.date : new Date(invoice.date);
            
            return (
                <div key={index} className="invoice-card">
                    <strong>Amount: {invoice.amount}</strong><br />
                    <span>Due Date: {invoiceDate ? invoiceDate.toLocaleDateString() : 'No due date available'}</span><br />
                    <span>Case: {invoice.caseTitle}</span><br />
                    <span>Description: {invoice.description || 'No description available'}</span><br />
                    <button onClick={() => handleInvoicePaid(invoice)}>Paid</button> {/* Add Paid Button */}
                </div>
            );
        })
    )}
</div>


    
            
            {/* Modal for adding a new to-do */}
            {isModalOpen && (
                <div className="modal">
                    <div className="modal-content">
                        <h3>Add New To-Do</h3>
                        <input
                            type="text"
                            placeholder="Description"
                            value={newTodo.description}
                            onChange={(e) => setNewTodo({ ...newTodo, description: e.target.value })}
                        />
                        <select
                            value={newTodo.assignedTo}
                            onChange={(e) => setNewTodo({ ...newTodo, assignedTo: e.target.value })}
                        >
                            <option value="">Assign to</option>
                            {teamMembers.map((member, index) => (
                                <option key={index} value={member.name}>
                                    {member.name}
                                </option>
                            ))}
                        </select>
                        <input
                            type="date"
                            value={newTodo.date}
                            onChange={(e) => setNewTodo({ ...newTodo, date: e.target.value })}
                        />
                        <button className="save-btn" onClick={handleAddTodo}>Save</button>
                        <button className="cancel-btn" onClick={() => setIsModalOpen(false)}>Cancel</button>
                    </div>
                </div>
            )}
        </div>
    );
}    

export default UpcomingTodos;
