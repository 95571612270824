import React, { useState, useEffect } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../firebaseConfig';
import { useNavigate } from 'react-router-dom';
import MultiStepFormLayout from './MultiStepFormLayout';
import './StateSelection.css'; // Add modern styling similar to HighCourtSelection

function StateSelection({ userData, setUserData }) {
  const [states, setStates] = useState([]);
  const navigate = useNavigate();
  const step = 2;
  const totalSteps = 7;
  const selectedStates = userData.selectedStates || [];

  // Fetch states from Firestore
  useEffect(() => {
    const fetchStates = async () => {
      const stateCollection = collection(db, 'districtstates');
      const stateSnapshot = await getDocs(stateCollection);
      setStates(stateSnapshot.docs.map(doc => doc.id));
    };
    fetchStates();
  }, []);

  // Handle state selection
  const toggleStateSelection = (state) => {
    setUserData(prevData => {
      const updatedStates = prevData.selectedStates.includes(state)
        ? prevData.selectedStates.filter(s => s !== state)
        : [...prevData.selectedStates, state];
      
      return {
        ...prevData,
        selectedStates: updatedStates,
      };
    });
  };

  // Proceed to the next step
  const handleNext = () => {
    setUserData(prevData => ({ ...prevData, selectedStates }));
    navigate('/district-selection', { state: { selectedStates } });
  };

  // Go back to the previous step
  const handleBack = () => {
    navigate(-1);
  };

  return (
    <MultiStepFormLayout 
      step={step}
      totalSteps={totalSteps}
      onNext={handleNext}
      isNextDisabled={selectedStates.length === 0}
    >
      <div className="state-selection-container">
        {states.map(state => (
          <div key={state} className="state-checkbox-container">
            <label className="state-label">
              <input
                type="checkbox"
                checked={selectedStates.includes(state)}
                onChange={() => toggleStateSelection(state)}
                className="state-checkbox"
              />
              {state}
            </label>
          </div>
        ))}
      </div>
    </MultiStepFormLayout>
  );
}

export default StateSelection;
