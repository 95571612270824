import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import MultiStepFormLayout from './MultiStepFormLayout'; // Common layout component for all registration steps
import './CATSelection.css'; // New CSS file for modern styling

function CATSelection({ userData, setUserData }) {
  const catBenches = [
    "Delhi", "Ahmedabad", "Allahabad", "Bangalore", "Chandigarh",
    "Chennai", "Cuttack", "Ernakulam", "Guwahati", "Hyderabad",
    "Jabalpur", "Jaipur", "Jammu", "Jodhpur", "Kolkata",
    "Lucknow", "Mumbai", "Srinagar", "Patna"
  ];
  const [selectedCATBench, setSelectedCATBench] = useState(userData.selectedCATBench || '');
  const navigate = useNavigate();
  const step = 6; // Example step number
  const totalSteps = 7;

  const handleNext = () => {
    // Update the selected CAT Bench in userData and then proceed to the next page
    setUserData(prevData => ({
      ...prevData,
      selectedCATBench
    }));
    navigate('/review-selection');
  };

  const handleBack = () => {
    navigate(-1); // Navigate to the previous page
  };

  return (
    <MultiStepFormLayout
      step={step}
      totalSteps={totalSteps}
      onNext={handleNext}
      onBack={handleBack}
      isNextDisabled={!selectedCATBench}
    >
      <div className="cat-selection-container">
        <label className="select-label" htmlFor="cat-bench">
          Select a CAT Bench:
        </label>
        <select
          id="cat-bench"
          value={selectedCATBench}
          onChange={(e) => setSelectedCATBench(e.target.value)}
          className="form-select"
        >
          <option value="">Select a CAT Bench</option>
          {catBenches.map(bench => (
            <option key={bench} value={bench}>
              {bench}
            </option>
          ))}
        </select>
      </div>
    </MultiStepFormLayout>
  );
}

export default CATSelection;
