import React, { useState, useEffect } from 'react';
import { doc, getDoc, collection, getDocs } from 'firebase/firestore';
import { db } from '../firebaseConfig';
import { useNavigate } from 'react-router-dom';
import MultiStepFormLayout from './MultiStepFormLayout'; // Common layout component for all registration steps
import './HighCourtSelection.css'; // New CSS file for modern styling

function HighCourtSelection({ userId, userData, setUserData }) {
  const [hcStates, setHcStates] = useState([]);
  const [hcBenchesMap, setHcBenchesMap] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState(null);
  const navigate = useNavigate();
  const step = 5; // Example step number
  const totalSteps = 7;

  const selectedHCStates = userData.selectedHCStates || {};

  useEffect(() => {
    loadHighCourtStates();
  }, []);

  const loadHighCourtStates = async () => {
    try {
      const snapshot = await getDocs(collection(db, 'HCStates'));
      const states = snapshot.docs.map(doc => doc.id);
      setHcStates(states);
    } catch (error) {
      console.error('Error loading High Court states:', error);
      setErrorMessage('Failed to load High Court states.');
    } finally {
      setIsLoading(false);
    }
  };

  const loadHCBenches = async (state) => {
    try {
      const docRef = doc(db, 'HCStates', state);
      const docSnapshot = await getDoc(docRef);
      if (docSnapshot.exists()) {
        const benches = docSnapshot.data().benches || [];
        setHcBenchesMap(prev => ({
          ...prev,
          [state]: benches
        }));
      }
    } catch (error) {
      console.error('Error loading High Court benches:', error);
      setErrorMessage(`Failed to load benches for ${state}.`);
    }
  };

  const toggleHighCourtStateSelection = (state) => {
    if (selectedHCStates[state]) {
      delete selectedHCStates[state];
    } else {
      loadHCBenches(state);
      selectedHCStates[state] = [];
    }
    setUserData({ ...userData, selectedHCStates });
  };

  const toggleBenchSelection = (state, bench) => {
    const benches = selectedHCStates[state] || [];
    if (benches.includes(bench)) {
      selectedHCStates[state] = benches.filter(b => b !== bench);
    } else {
      selectedHCStates[state].push(bench);
    }
    setUserData({ ...userData, selectedHCStates });
  };

  const handleNext = () => {
    navigate('/cat-selection');
  };

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <MultiStepFormLayout
      step={step}
      totalSteps={totalSteps}
      onNext={handleNext}
      onBack={handleBack}
      isNextDisabled={Object.keys(selectedHCStates).length === 0}
      isLoading={isLoading}
      errorMessage={errorMessage}
    >
      <div className="hc-selection-container">
        {hcStates.map(state => (
          <div key={state} className="hc-state">
            <label className="state-label">
              <input
                type="checkbox"
                checked={!!selectedHCStates[state]}
                onChange={() => toggleHighCourtStateSelection(state)}
                className="state-checkbox"
              />
              {state}
            </label>
            {selectedHCStates[state] && hcBenchesMap[state] ? (
              <div className="bench-container">
                {hcBenchesMap[state].length > 0 ? (
                  hcBenchesMap[state].map(bench => (
                    <label key={bench} className="bench-label">
                      <input
                        type="checkbox"
                        checked={selectedHCStates[state].includes(bench)}
                        onChange={() => toggleBenchSelection(state, bench)}
                        className="bench-checkbox"
                      />
                      {bench}
                    </label>
                  ))
                ) : (
                  <p>No benches available for {state}</p>
                )}
              </div>
            ) : selectedHCStates[state] && !hcBenchesMap[state] ? (
              <p>Loading benches...</p>
            ) : null}
          </div>
        ))}
      </div>
    </MultiStepFormLayout>
  );
}

export default HighCourtSelection;
