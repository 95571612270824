import React, { useEffect, useLayoutEffect, useState, useRef } from 'react';
import { db, auth } from '../firebaseConfig'; // Make sure to import auth
import { collection, doc, getDoc, getDocs, onSnapshot } from 'firebase/firestore';
import './Dashboard.css';
import { useCallback } from 'react';
import { updateDoc, arrayUnion } from 'firebase/firestore';
import CaseDetail from './CaseDetail'; // Import the CaseDetail component
import TopBar from './TopBar'; // Import TopBar component
import { useUser } from '../UserContext'; // Import the useUser hook
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import waitingAnimation from '../assets/waiting.json';
import Lottie from 'lottie-react';
import moment from 'moment';


function Dashboard() {
  const navigate = useNavigate();
  const location = useLocation(); // Use useLocation hook
  const { userData } = useUser(); // Get userData from context
  const [cases, setCases] = useState([]);
  const [filteredCases, setFilteredCases] = useState([]);
  const fromRegistration = location.state?.fromRegistration || false; // Check if user came from registration
  const [isLoading, setIsLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedCase, setSelectedCase] = useState(null); // State for selected case
  const [isMobileView, setIsMobileView] = useState(false); // State to toggle mobile view
  const [userId, setUserId] = useState(useParams().userId || null); // Use useState to define userId and setUserId
  const [isCaseDetailVisible, setCaseDetailVisible] = useState(false);
  const caseDetailRef = useRef(null); // Define a ref for the case detail container

  useEffect(() => {
    if (fromRegistration) {
      const timer = setTimeout(() => {
        // Perform additional actions after 10 minutes if needed
      }, 10 * 60 * 1000); // 10 minutes
      return () => clearTimeout(timer); // Clean up the timer
    }
  }, [fromRegistration]);

  useLayoutEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 768);
    };
  
    window.addEventListener('resize', handleResize);
    handleResize(); // Call initially to set the correct layout mode
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const parseCase = useCallback((document) => {
    const caseType = document.get("caseType");

    switch (caseType) {
      case "HighCourt":
        return createHighCourtCase(document);
      case "DistrictCourt":
        return createDistrictCourtCase(document);
      case "CATCases":
        return createCATCase(document);
      default:
        return { id: document.id, title: "Unknown Title", court: "Unknown Court", nextDate: "No Next Hearing Date" };
    }
  }, []);

  const fetchCases = useCallback(() => {
    setIsLoading(true);
    const casesRef = collection(db, 'users', userId, 'CaseDetails');

    // Use Firestore's onSnapshot to get real-time updates
    const unsubscribe = onSnapshot(casesRef, (querySnapshot) => {
        const casesData = querySnapshot.docs.map(doc => parseCase(doc));

        // Get today's date in IST (Indian Standard Time)
        const today = new Date();
        today.setHours(0, 0, 0, 0); // Reset to midnight to compare only dates

       // Sort the cases by next hearing date (nearest first)
        const sortedCases = casesData.sort((a, b) => {
          const dateA = parseDate(a.nextDate);
          const dateB = parseDate(b.nextDate);

          if (dateA.getTime() === today.getTime() && dateB.getTime() === today.getTime()) {
              return 0; // If both dates are today, keep the original order
          }
          if (dateA.getTime() === today.getTime()) return -1; // Today's cases come first
          if (dateB.getTime() === today.getTime()) return 1; // Today's cases come first

          if (dateA < today && dateB < today) {
              return dateB - dateA; // Sort past dates
          }
          if (dateA < today) return 1; // Past dates come last
          if (dateB < today) return -1; // Future dates come first

          return dateA - dateB; // Sort future dates
        });

        setCases(sortedCases);
        setFilteredCases(sortedCases);
        setIsLoading(false);
    });

    return () => unsubscribe(); // Clean up the listener on unmount
}, [userId]);

  
  useEffect(() => {
    const storedUserId = userId || localStorage.getItem('userId');
    if (storedUserId) {
        setUserId(storedUserId);
        fetchCases();  // Fetch cases once userId is available
    } else {
        navigate('/login');
    }
}, [userId, fetchCases, navigate]);

 // Helper function to parse the nextDate string into a Date object
const parseDate = (dateString) => {
  if (!dateString) return new Date(8640000000000000); // Set to distant future if no date

  // Remove ordinal suffixes
  const cleanedDateString = dateString.replace(/(st|nd|rd|th)/g, '').trim();

  // Normalize the date format by adding leading zero to the month if needed
  const parts = cleanedDateString.split('/');
  if (parts.length === 3) {
      parts[1] = parts[1].padStart(2, '0'); // Add leading zero to month
      return new Date(parts.join('/')); // Format back to 'DD/MM/YYYY'
  }

  // Define various date formats to parse
  const dateFormats = [
      "DD/MM/YYYY", // Format: 25/09/2024
      "DD-MM-YYYY", // Format: 25-09-2024
      "DD MMMM YYYY", // Format: 26 September 2024
      "DD MMM YYYY", // Format: 26 Sep 2024
      "MMMM DD, YYYY", // Format: September 26, 2024
      "MMMM DD YYYY", // Format: September 26 2024
  ];

  // Try to parse the date with moment.js
  for (const format of dateFormats) {
      const momentDate = moment(cleanedDateString, format, true);
      if (momentDate.isValid()) {
          return momentDate.toDate();
      }
  }

  // If the date is invalid, return a distant future date
  return new Date(8640000000000000);
};


  useEffect(() => {
    fetchCases(); // Now it will run without warnings
  }, [fetchCases]);  // Add fetchCases as a dependency


  const createHighCourtCase = (document) => {
    const petitionerAdvocate = document.get("petitioner_advocate") || "Unknown Petitioner";
    const respondentAdvocate = document.get("respondent_advocate") || "Unknown Respondent";
    const caseStatus = document.get("case_status") || {};

    return {
      id: document.id,
      title: `${petitionerAdvocate} vs ${respondentAdvocate}`,  // Use the correct fields for petitioner and respondent
      nextDate: caseStatus["Next Hearing Date"] || "No Next Hearing Date",
      court: caseStatus["Coram"] || "Unknown Court",
    };
  };


  const createDistrictCourtCase = (document) => {
    const petitioners = document.get("Petitioners") || ["Unknown Petitioner"];
    const respondents = document.get("Respondents") || ["Unknown Respondent"];
    return {
      id: document.id,
      title: `${petitioners.join(", ")} vs ${respondents.join(", ")}`,
      nextDate: document.get("NextHearingDate") || "No Next Hearing Date",
      court: document.get("CourtNumberAndJudge") || "Unknown Court",
      petitionerAdvocate: Array.isArray(petitioners) ? petitioners : [petitioners],
      respondentAdvocate: Array.isArray(respondents) ? respondents : [respondents],
    };
  };

  const createCATCase = (document) => {
    const petitioner = document.get("Petitioner(s)") || "Unknown Petitioner";
    const respondent = document.get("Respondent(s)") || "Unknown Respondent";

    const nextListingDate = document.get("next_hearing_date"); // Retrieve the date string
    const parsedNextDate = nextListingDate ? parseDate(nextListingDate) : new Date(8640000000000000); // Use a distant future date if missing

    return {
        id: document.id,
        title: `${petitioner} vs ${respondent}`,
        nextDate: nextListingDate ? parsedNextDate.toLocaleDateString('en-IN') : "No Next Hearing Date", // Format the date for display
        court: document.get("Case Proceeding Details")?.[0]?.["Court Name"] || "Unknown Court",
    };
};



  const handleSearch = (query) => {
    setSearchQuery(query);
    const filtered = cases.filter(c =>
      c.title?.toLowerCase().includes(query.toLowerCase()) ||
      (typeof c.petitionerAdvocate === 'string' && c.petitionerAdvocate.toLowerCase().includes(query.toLowerCase())) ||
      (typeof c.respondentAdvocate === 'string' && c.respondentAdvocate.toLowerCase().includes(query.toLowerCase()))
    );
    setFilteredCases(filtered);
  };

  useEffect(() => {
    if (isMobileView) {
      const storedCase = sessionStorage.getItem('mobileSelectedCase');
      if (storedCase) {
        setSelectedCase(JSON.parse(storedCase));
        setCaseDetailVisible(true);
      }
    }
  }, [isMobileView]);
  
  
  const handleCaseClick = (caseData) => {
    // Clear any previous case in sessionStorage
    sessionStorage.removeItem('mobileSelectedCase');

    // Set new case data in sessionStorage for mobile persistence
    sessionStorage.setItem('mobileSelectedCase', JSON.stringify(caseData));
    setSelectedCase(caseData);

    // Make the case detail visible immediately
    setCaseDetailVisible(true);

    const caseDocRef = doc(db, 'users', userId, 'CaseDetails', caseData.id);
  
    // Fetch case details from Firestore
    const unsubscribe = onSnapshot(caseDocRef, (caseDoc) => {
        if (caseDoc.exists()) {
            const fullCaseDetails = caseDoc.data();
            const caseDetailData = {
                ...caseData,
                petitionerAdvocate: fullCaseDetails.Petitioners?.join(", ") || "Unknown Petitioner",
                respondentAdvocate: fullCaseDetails.Respondents?.join(", ") || "Unknown Respondent",
                caseType: fullCaseDetails.caseType || "Unknown Case Type",
                title: `${fullCaseDetails.Petitioners?.join(", ")} vs ${fullCaseDetails.Respondents?.join(", ")}`,
                caseNumber: fullCaseDetails.CNRNumber || "Unknown Case Number",
                nextHearingDate: fullCaseDetails.NextHearingDate || "No Next Hearing Date",
                courtNumber: fullCaseDetails["Court Number and Judge"] || "Unknown Court",
                caseStage: fullCaseDetails["Case Stage"] || "N/A",
                chatGptSuggestions: fullCaseDetails.ChatGPTSuggestions || "No suggestions available",
                payments: fullCaseDetails.payments || [],
                pendingInvoices: fullCaseDetails.pendingInvoices || [],
                notes: fullCaseDetails.notes || [],
                toDos: fullCaseDetails.toDos || [],
                orders: fullCaseDetails.orders || [],
                caseStatus: fullCaseDetails.case_status || {},
                caseHistory: fullCaseDetails.CaseHistory || [],
            };

            setSelectedCase(caseDetailData);

            // Scroll to the case detail if necessary
            if (caseDetailRef.current) {
                caseDetailRef.current.scrollIntoView({ behavior: 'smooth' });
            }

            // Add full-screen effect for mobile view
            if (isMobileView) {
                document.querySelector('.case-detail-container').classList.add('full-screen');
                document.querySelector('.case-list-container').classList.add('case-list-hidden'); // Hide case list on mobile
            }
        }
    });

    // Clean up the Firestore subscription on unmount
    return () => unsubscribe();
};

  
  const handleBackToList = () => {
    setCaseDetailVisible(false);
    setTimeout(() => {
      setSelectedCase(null);
      if (isMobileView) {
        document.querySelector('.case-detail-container').classList.remove('full-screen');
        document.querySelector('.case-list-container').classList.remove('case-list-hidden');
      }
    }, 300);
  };
  
  
  
  return (
    <div className="dashboard-container">
      <div className={`case-list-container ${selectedCase && isMobileView ? 'hide-case-list' : ''}`}>
        <SearchBar value={searchQuery} onSearch={handleSearch} />
        {isLoading ? (
          <p>Loading cases...</p>
        ) : filteredCases.length === 0 ? (
          <p>No cases found</p>
        ) : (
          <div className="case-list">
            {filteredCases.map(c => (
              <CaseCard key={c.id} caseData={c} selectedCaseId={selectedCase?.id} onClick={() => handleCaseClick(c)} />
            ))}
          </div>
        )}
      </div>

      <div className={`case-detail-container ${isCaseDetailVisible ? 'show' : ''}`} ref={caseDetailRef}>
        {fromRegistration && !selectedCase ? (
          <div className="fetching-message">
            <h3>Your cases are being fetched and will appear in 5-10 minutes. Meanwhile, check our legal AI trained on Indian laws, document drafting and other features.</h3>
            <Lottie
              animationData={waitingAnimation}
              autoplay
              loop
              style={{ height: '300px', width: '300px' }}
            />
          </div>
        ) : selectedCase ? (
          <>
            <button className="back-button" onClick={handleBackToList}>Back to Cases</button>
            <CaseDetail caseData={selectedCase} userId={userId} />
          </>
        ) : (
          <p>Select a case to view details.</p>
        )}
      </div>
    </div>
  );
}


function SearchBar({ value, onSearch }) {
  return (
    <input
      type="text"
      value={value}
      onChange={(e) => onSearch(e.target.value)}
      placeholder="Search cases..."
      style={{
        padding: '10px',
        margin: '20px 0',
        width: '100%',
        boxSizing: 'border-box'
      }}
    />
  );
}

function CaseCard({ caseData, onClick, selectedCaseId }) {
  const isSelected = selectedCaseId === caseData.id;

  return (
    <div
      className={`case-card ${isSelected ? 'selected' : ''}`}
      onClick={onClick}
      style={{
        padding: '20px',
        margin: '10px 0',
        border: '1px solid #ddd',
        borderRadius: '5px',
        cursor: 'pointer',
        backgroundColor: isSelected ? '#e0e0e0' : '#fff',
        borderColor: isSelected ? '#6200EA' : '#ddd',
        transition: 'background-color 0.3s ease, border-color 0.3s ease',
      }}
    >
      <h3>{caseData.title || "Unknown Title"}</h3>
      <p><strong>Court:</strong> {caseData.court || "Unknown Court"}</p>
      <p><strong>Next Hearing Date:</strong> {caseData.nextDate || "No Next Hearing Date"}</p>

      {caseData.caseType === "HighCourt" && (
        <div>
          <p><strong>High Court:</strong> {caseData.court}</p>
          <p><strong>Coram:</strong> {caseData.court || "N/A"}</p>
          <p><strong>Next Hearing:</strong> {caseData.nextDate || "No Next Hearing Date"}</p>
        </div>
      )}

      {caseData.caseType === "DistrictCourt" && (
        <div>
          <p><strong>CNR Number:</strong> {caseData.title || "Unknown CNR"}</p>
          <p><strong>Court:</strong> {caseData.court || "N/A"}</p>
          <p><strong>Next Hearing:</strong> {caseData.nextDate || "No Next Hearing Date"}</p>
        </div>
      )}

      {caseData.caseType === "CATCases" && (
                <div>
                    <p><strong>Case Number:</strong> {caseData.title || "Unknown Case Number"}</p>
                    <p><strong>Court:</strong> {caseData.court || "N/A"}</p>
                    <p><strong>Next Listing Date:</strong> {caseData.nextDate || "No Next Listing Date"}</p>
                </div>
      )}
    </div>
  );
}

export default Dashboard;
