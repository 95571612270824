import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom'; // Import useNavigate
import { signOut } from 'firebase/auth';
import './TopBar.css';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import { auth } from '../firebaseConfig';

function TopBar({ userId }) {
    const [isMobile, setIsMobile] = useState(false);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const location = useLocation();
    const navigate = useNavigate(); // Initialize navigate function

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const toggleDrawer = () => {
        setDrawerOpen(!drawerOpen);
    };

    const handleLogout = async () => {
        try {
            await signOut(auth);
            navigate('/login'); // Use navigate to go to the login page
        } catch (error) {
            console.error('Error signing out:', error);
        }
    };

    const isActive = (path) => location.pathname === path ? 'active' : '';

    return (
        <div className="top-bar">
            <h1 className="dashboard-title">GenLawyers</h1>
            {!isMobile ? (
                <nav className="top-nav">
                    <Link to={`/dashboard/${userId}`} className={`nav-link ${isActive(`/dashboard/${userId}`)}`}>Dashboard</Link>
                    <Link to={`/ai-chat/${userId}`} className={`nav-link ${isActive(`/ai-chat/${userId}`)}`}>AI Chat</Link>
                    <Link to={`/profile/${userId}`} className={`nav-link ${isActive(`/profile/${userId}`)}`}>Profile</Link>
                    <Link to={`/calendar/${userId}`} className={`nav-link ${isActive(`/calendar/${userId}`)}`}>Calendar</Link>
                    <Link to={`/team/${userId}`} className={`nav-link ${isActive(`/team/${userId}`)}`}>Team</Link>
                    <Link to={`/docdraft/${userId}`} className={`nav-link ${isActive(`/docdraft/${userId}`)}`}>DocDraft</Link>
                    <Link to={`/upcoming-todos/${userId}`} className={`nav-link ${isActive(`/upcoming-todos/${userId}`)}`}>Upcoming</Link>
                    <Link to={`/add-case`} className={`nav-link ${isActive(`/add-case`)}`}>Add Case</Link>                     
                    <button onClick={handleLogout} className="nav-link">Logout</button>
                </nav>
            ) : (
                <div className="hamburger-menu" onClick={toggleDrawer}>
                    <MenuIcon style={{ color: 'white' }} />
                </div>
            )}
            {isMobile && drawerOpen && (
                <div className="drawer">
                    <div className="drawer-header">
                        <CloseIcon onClick={toggleDrawer} style={{ cursor: 'pointer' }} />
                    </div>
                    <nav className="drawer-nav">
                        <Link to={`/dashboard/${userId}`} className={`drawer-link ${isActive(`/dashboard/${userId}`)}`} onClick={toggleDrawer}>Dashboard</Link>
                        <Link to={`/ai-chat/${userId}`} className={`drawer-link ${isActive(`/ai-chat/${userId}`)}`} onClick={toggleDrawer}>AI Chat</Link>
                        <Link to={`/profile/${userId}`} className={`drawer-link ${isActive(`/profile/${userId}`)}`} onClick={toggleDrawer}>Profile</Link>
                        <Link to={`/calendar/${userId}`} className={`drawer-link ${isActive(`/calendar/${userId}`)}`} onClick={toggleDrawer}>Calendar</Link>
                        <Link to={`/team/${userId}`} className={`drawer-link ${isActive(`/team/${userId}`)}`} onClick={toggleDrawer}>Team</Link>
                        <Link to={`/docdraft/${userId}`} className={`drawer-link ${isActive(`/docdraft/${userId}`)}`} onClick={toggleDrawer}>DocDraft</Link>
                        <Link to={`/upcoming-todos/${userId}`} className={`drawer-link ${isActive(`/upcoming-todos/${userId}`)}`} onClick={toggleDrawer}>Upcoming</Link>
                        <Link to={`/add-case`} className={`nav-link ${isActive(`/add-case`)}`}>Add Case</Link> 
                        <button onClick={() => { handleLogout(); toggleDrawer(); }} className="drawer-link">Logout</button>
                    </nav>
                </div>
            )}
        </div>
    );
}

export default TopBar;
